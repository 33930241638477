import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-checks-v2--line-item--radio-button"
export default class extends Controller {
  static values = {
    formId: String,
    filterType: String,
  }
  formIdValue: string
  filterTypeValue: string

  connect() {}

  submitForm() {
    const form = document.getElementById(this.formIdValue) as HTMLFormElement
    const filterType = document.getElementById("filter_type") as HTMLInputElement

    filterType.value = this.filterTypeValue

    form.requestSubmit()
  }
}
